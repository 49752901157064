import OtpClient from "./otpclient";
class BidService {
    async getWallet() {
        const response = await new OtpClient().post("Users/UserGetWalletAmount");
        return response.envelopeBody;
    }
    getBids(vehicleId) {
        return new OtpClient().collection("Bidding/GetBids", vehicleId);
    }
    async bid(body) {
        await new OtpClient().post("Bidding/QuickBid", body);
    }
}
const bidService = new BidService();
export default bidService;
