import { defineComponent, ref } from "vue";
import { useRouter } from 'vue-router';
import { closeModal } from "jenesius-vue-modal";
import DxValidationSummary from "devextreme-vue/validation-summary";
import DxForm, { DxRequiredRule, DxGroupItem, DxItem, DxLabel, DxButtonItem } from "devextreme-vue/form";
import DxRadioGroup from "devextreme-vue/radio-group";
import { alert } from "devextreme/ui/dialog";
import { formatNumber } from "devextreme/localization";
import paymentService from "../../services/payment";
export default defineComponent({
    props: {
        showNav: Boolean
    },
    setup(props) {
        const router = useRouter();
        const data = ref({ type: "Credit Card", totalVehicles: 1, chargeAmount: 100, terms: false });
        const errors = ref([]);
        const busy = ref(false);
        const paymentTypes = ["Credit Card", "Bank Transfer", "PayPal"];
        async function handlePaymentClick(e) {
            e.preventDefault();
            errors.value = [];
            busy.value = true;
            const result = await paymentService.creditCard(data.value);
            if (!result.paymentSuccessful) {
                errors.value = [result.responseText];
            }
            else {
                window.dispatchEvent(new CustomEvent("Wallet"));
                await alert(formatNumber(data.value.chargeAmount, "currency") + " has been added to your wallet.", "Payment Successful");
                if (props.showNav) {
                    router.push({
                        name: "user-profile-preferences"
                    });
                }
                else {
                    closeModal();
                }
            }
            busy.value = false;
        }
        return {
            data,
            errors,
            busy,
            paymentTypes,
            handlePaymentClick,
            formatNumber
        };
    },
    components: {
        DxValidationSummary,
        DxForm,
        DxRequiredRule,
        DxGroupItem,
        DxItem,
        DxLabel,
        DxButtonItem,
        DxRadioGroup // eslint-disable-line vue/no-unused-components
    }
});
