<template>
    <div class="d-flex vw-100 vh-100">
        <section class="side content">
            <img src="../assets/app_logo.svg" alt="Off the Block" title="Off the Block" class="logo" />
            <slot />
        </section>
        <div class="flex-grow-1">
            <img class="bg" src="../assets/otb-login-screen-bg.jpg" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .logo {
        display: block;
        margin: auto;
        margin-bottom: 60px;
        width: 60%;
    }

    .bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.75;
    }

    .side {
        box-shadow: 5px 0 10px 0 rgba(0,0,0,0.75);
        width: 425px;
        min-width: 425px;
    }

    .content {
        padding: 55px;
    }
</style>