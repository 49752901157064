import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_b_button = _resolveComponent("b-button");
    return (_openBlock(), _createBlock(_component_b_button, {
        variant: _ctx.variant,
        to: { name: _ctx.name, params: _ctx.params }
    }, {
        default: _withCtx(() => [
            _createElementVNode("i", {
                class: _normalizeClass(_ctx.icon)
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
        ]),
        _: 1
    }, 8, ["variant", "to"]));
}
