import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import DxList from "devextreme-vue/ui/list";
import navigation from '../dealer-navigation';
export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const listRef = ref();
        function handleItemClick(e) {
            router.push(e.itemData.path);
        }
        function updateSelection() {
            if (listRef.value) {
                const index = navigation.findIndex(_ => _.path == route.path || route.path.startsWith(_.path));
                listRef.value.instance.selectItem(index);
            }
        }
        onMounted(() => updateSelection());
        watch(() => route.path, () => updateSelection());
        return {
            navigation,
            handleItemClick,
            listRef
        };
    },
    components: {
        DxList
    }
});
