import urlJoin from 'url-join';
import auth from './auth';
const apiUrl = process.env.VUE_APP_API;
export default class OtpClient {
    async post(path, body = undefined) {
        return this.fetch(path, body);
    }
    async collection(path, body = undefined) {
        const response = await this.fetch(path, body);
        if (response.envelopeHeader.totalSize === 0) {
            return emptyResponse;
        }
        return { data: response.envelopeBody, totalCount: response.envelopeHeader.totalSize };
    }
    async fetch(path, body = undefined) {
        const url = this.buildUrl(path);
        const response = await fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.token,
                "RequesterUserId": auth.user?.userId || ""
            },
            body: this.buildBody(body)
        });
        if (response.status === 200) {
            const contentType = response.headers.get("content-type");
            if (!contentType || contentType.indexOf("application/json") === -1) {
                return {};
            }
        }
        const result = await response.json();
        if (result.envelopeFooter && result.envelopeFooter.errorOccured) {
            throw result.envelopeFooter.errorMessage;
        }
        return result;
    }
    buildUrl(path, params) {
        const url = new URL(urlJoin(apiUrl, path));
        url.search = new URLSearchParams(params).toString();
        return url.toString();
    }
    buildBody(body) {
        return JSON.stringify({
            envelopeHeader: {
                requesterId: auth.user?.userId,
                requestDateTimeStamp: new Date().toISOString()
            },
            envelopeBody: body
        });
    }
}
export const emptyResponse = { data: [], totalCount: 0 };
