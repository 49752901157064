import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("span", null, [
        _createElementVNode("i", {
            class: _normalizeClass(["text-secondary", _ctx.icon])
        }, null, 2),
        _createTextVNode(),
        _renderSlot(_ctx.$slots, "default")
    ]));
}
