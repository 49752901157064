import { defineComponent, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
import { container } from "jenesius-vue-modal";
import { useSignalR } from '@dreamonkey/vue-signalr';
import { useToast } from 'vue-toast-notification';
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import auth from "./services/auth";
function getScreenSizeInfo() {
    const screenSizes = sizes();
    return {
        isXSmall: screenSizes["screen-x-small"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
    };
}
export default defineComponent({
    name: 'App',
    compatConfig: {
        GLOBAL_MOUNT_CONTAINER: true
    },
    setup() {
        const signalr = useSignalR();
        const $toast = useToast();
        if (auth.loggedIn()) {
            signalr.invoke('SubscribeToAuctions');
        }
        signalr.on("toast", message => {
            $toast.success(message, {
                position: "top-right",
                duration: 10000
            });
        });
        const screen = reactive({ info: {} });
        screen.info = getScreenSizeInfo();
        const cssClasses = computed(() => {
            return ["app"].concat(screen.info.cssClasses);
        });
        function screenSizeChanged() {
            screen.info = getScreenSizeInfo();
        }
        function getLayout(route) {
            if (route.meta.layout && route.meta.layout.constructor === Function) {
                return route.meta.layout(auth.user);
            }
            return route.meta.layout;
        }
        onMounted(() => {
            subscribe(screenSizeChanged);
        });
        onBeforeUnmount(() => {
            unsubscribe(screenSizeChanged);
        });
        return {
            screen,
            cssClasses,
            getLayout
        };
    },
    components: {
        WidgetContainerModal: container
    }
});
