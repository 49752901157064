import { defineComponent } from "vue";
import NavToolbar from "../components/nav-toolbar.vue";
import auth from "../services/auth";
export default defineComponent({
    props: {
        isXSmall: Boolean,
        isLarge: Boolean
    },
    setup() {
        return {
            auth
        };
    },
    components: {
        NavToolbar
    }
});
