import OtpClient from "./otpclient";
class AuthService {
    cachedUser = null;
    get user() {
        if (this.cachedUser) {
            return this.cachedUser;
        }
        const user = sessionStorage.getItem("user");
        if (user) {
            return this.cachedUser = JSON.parse(user);
        }
        return null;
    }
    set user(value) {
        this.cachedUser = value;
        if (value) {
            sessionStorage.setItem("user", JSON.stringify(value));
        }
        else {
            sessionStorage.removeItem("user");
        }
    }
    get token() {
        return sessionStorage.getItem("token");
    }
    set token(value) {
        if (value) {
            sessionStorage.setItem("token", value);
        }
        else {
            sessionStorage.removeItem("token");
        }
    }
    loggedIn() {
        return !!this.user;
    }
    async login(body) {
        const response = await new OtpClient().post("Login/UserLogin", body);
        this.user = response.envelopeBody.user;
        this.token = response.envelopeBody.token;
    }
    async reset(body) {
        const response = await new OtpClient().post("Login/UserForgotPassword", body);
        return response.envelopeBody;
    }
    async resetMfa(body) {
        const response = await new OtpClient().post("Login/UserMFAPasswordReset", body);
        return response.envelopeBody;
    }
    async logOut() {
        this.user = null;
        this.token = null;
    }
}
const authService = new AuthService();
export default authService;
