import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vueperslides/dist/vueperslides.css';
import './themes/generated/dx.light.black.css';
import "./styles.scss";
import { createApp } from 'vue';
import router from "./router";
import BootstrapVue3 from 'bootstrap-vue-3';
import ToastPlugin from 'vue-toast-notification';
import { VueSignalR } from '@dreamonkey/vue-signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import DataGrid from "devextreme/ui/data_grid";
import Form from "devextreme/ui/form";
import LoadPanel from "devextreme/ui/load_panel";
import App from './App.vue';
import BtnNav from "./components/btn-nav.vue";
import IconLabel from "./components/icon-label.vue";
DataGrid.defaultOptions({
    options: {
        editing: {
            useIcons: true
        },
        loadPanel: {
            indicatorSrc: "https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
        },
        showColumnLines: false
    }
});
Form.defaultOptions({
    options: {
        labelMode: 'floating'
    }
});
LoadPanel.defaultOptions({
    options: {
        indicatorSrc: "https://js.devexpress.com/Content/data/loadingIcons/rolling.svg",
        shadingColor: "rgba(0,0,0, 0.75)"
    }
});
const connection = new HubConnectionBuilder()
    .withUrl(process.env.VUE_APP_HUB, {
    accessTokenFactory: () => sessionStorage.getItem("token") || "",
    withCredentials: false
})
    .withAutomaticReconnect()
    .build();
window["connection"] = connection;
console.log(process.env.VUE_APP_API);
console.log(process.env.VUE_APP_HUB);
const app = createApp(App);
app.component("BtnNav", BtnNav);
app.component("IconLabel", IconLabel);
app.use(BootstrapVue3);
app.use(ToastPlugin, {
    position: 'top-right'
});
app.use(VueSignalR, { connection });
app.use(router);
app.mount('#app');
