import { defineComponent, computed, ref, watch } from "vue";
import { useRoute } from 'vue-router';
import DxDrawer from "devextreme-vue/drawer";
import HeaderToolbar from "../components/header-toolbar.vue";
import SideNavMenu from "../components/side-nav-menu.vue";
import auth from "../services/auth";
export default defineComponent({
    props: {
        isXSmall: Boolean,
        isLarge: Boolean
    },
    setup(props) {
        const route = useRoute();
        const menuOpened = ref(props.isLarge);
        function toggleMenu() {
            menuOpened.value = !menuOpened.value;
        }
        const drawerOptions = computed(() => {
            const shaderEnabled = !props.isLarge;
            return {
                menuMode: props.isLarge ? "shrink" : "overlap",
                minSize: props.isXSmall ? 0 : 38,
                maxSize: props.isXSmall ? 200 : undefined,
                closeOnOutsideClick: shaderEnabled,
                shaderEnabled
            };
        });
        watch(() => route.path, () => {
            if (!props.isLarge) {
                menuOpened.value = false;
            }
        });
        watch(() => props.isLarge, value => menuOpened.value = value);
        return {
            auth,
            menuOpened,
            toggleMenu,
            drawerOptions
        };
    },
    components: {
        DxDrawer,
        HeaderToolbar,
        SideNavMenu
    }
});
