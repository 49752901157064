import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4881fa1c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "vh-100 d-flex flex-column"
};
const _hoisted_2 = { class: "content flex-grow-1" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_NavToolbar = _resolveComponent("NavToolbar");
    return (_ctx.auth.loggedIn())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NavToolbar),
            _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
        ]))
        : _createCommentVNode("", true);
}
