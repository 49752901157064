import { defineComponent } from "vue";
export default defineComponent({
    props: {
        text: String,
        icon: String,
        variant: {
            type: String,
            default: () => "primary"
        },
        name: {
            type: String,
            required: true
        },
        params: Object
    }
});
