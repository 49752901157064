import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44b8cf3a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "shadow-sm" };
const _hoisted_2 = { class: "m-0" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DxItem = _resolveComponent("DxItem");
    const _component_b_avatar = _resolveComponent("b-avatar");
    const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
    const _component_b_dropdown = _resolveComponent("b-dropdown");
    const _component_DxToolbar = _resolveComponent("DxToolbar");
    return (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createVNode(_component_DxToolbar, null, {
            default: _withCtx(() => [
                _createVNode(_component_DxItem, {
                    location: "before",
                    widget: "dxButton",
                    options: { icon: 'menu', stylingMode: 'text', onClick: _ctx.toggleMenuFunc }
                }, null, 8, ["options"]),
                _createVNode(_component_DxItem, { location: "before" }, {
                    default: _withCtx(() => [
                        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_DxItem, { location: "after" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_b_dropdown, {
                            id: "avatar",
                            variant: "link"
                        }, {
                            "button-content": _withCtx(() => [
                                _createVNode(_component_b_avatar, {
                                    variant: "primary",
                                    text: _ctx.user
                                }, null, 8, ["text"])
                            ]),
                            default: _withCtx(() => [
                                _createVNode(_component_b_dropdown_item, { to: "/profile" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode("Profile")
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_b_dropdown_item, { onClick: _ctx.handleLogOutclick }, {
                                    default: _withCtx(() => [
                                        _createTextVNode("Sign out")
                                    ]),
                                    _: 1
                                }, 8, ["onClick"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        })
    ]));
}
