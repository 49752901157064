import { createRouter, createWebHashHistory } from "vue-router";
import bidderView from "./layouts/layout-bidder-view.vue";
import sideNavView from "./layouts/layout-side-nav.vue";
import sideView from "./layouts/layout-side-view.vue";
import auth from "./services/auth";
function loadView(view) {
    return () => import(`./views/${view}.vue`);
}
const router = createRouter({
    routes: [
        {
            path: "/home",
            name: "home",
            redirect: "" // Redirect will be handled below.
        },
        {
            path: "/contact",
            name: "contact",
            meta: {
                title: "Contact",
                layout: bidderView
            },
            component: loadView("user/view-contact")
        },
        {
            path: "/profile",
            name: "profile",
            meta: {
                title: "Profile",
                layout: (user) => user.isDealershipUser || user.isSystemsAdministrator ? sideNavView : bidderView
            },
            component: loadView("view-profile")
        },
        {
            path: "/user-profile-complete",
            name: "user-profile-complete",
            meta: {
                title: "Profile",
                layout: bidderView
            },
            component: loadView("user/view-profile-complete")
        },
        {
            path: "/user-profile-funds",
            name: "user-profile-funds",
            meta: {
                title: "Profile",
                layout: bidderView
            },
            component: loadView("user/view-profile-funds")
        },
        {
            path: "/user-profile-preferences",
            name: "user-profile-preferences",
            meta: {
                title: "Profile",
                layout: bidderView
            },
            component: loadView("user/view-profile-preferences")
        },
        {
            path: "/user-home",
            name: "user-home",
            meta: {
                title: "Home",
                layout: bidderView
            },
            component: loadView("user/view-home")
        },
        {
            path: "/user-auctions",
            name: "user-auctions",
            meta: {
                title: "Auctions",
                layout: bidderView
            },
            component: loadView("user/view-auctions"),
            props: true
        },
        {
            path: "/user-watchlist",
            name: "user-watchlist",
            meta: {
                title: "Watchlist",
                layout: bidderView
            },
            component: loadView("user/view-watchlist")
        },
        {
            path: "/dealer-home",
            name: "dealer-home",
            meta: {
                title: "Home",
                layout: sideNavView
            },
            component: loadView("dealer/view-home")
        },
        {
            path: "/dealer-auctions",
            name: "dealer-auctions",
            meta: {
                title: "Auctions",
                layout: sideNavView
            },
            component: loadView("dealer/view-auctions")
        },
        {
            path: "/auctions/:id?/detail",
            name: "auction-detail",
            meta: {
                title: "Auctions",
                layout: sideNavView
            },
            component: loadView("dealer/view-auction-detail"),
            props: true
        },
        {
            path: "/bidders",
            name: "bidders",
            meta: {
                layout: sideNavView
            },
            component: loadView("view-bidders")
        },
        {
            path: "/clients",
            name: "clients",
            meta: {
                title: "Dealers",
                layout: sideNavView
            },
            component: loadView("view-clients")
        },
        {
            path: "/clients/:id?/detail",
            name: "client-detail",
            meta: {
                title: "Dealers",
                layout: sideNavView
            },
            component: loadView("view-client-detail"),
            props: true
        },
        {
            path: "/vehicles",
            name: "vehicles",
            meta: {
                title: "Vehicles",
                layout: sideNavView
            },
            component: loadView("view-vehicles")
        },
        {
            path: "/vehicles/:id?/detail",
            name: "vehicle-detail",
            meta: {
                title: "Vehicles",
                layout: sideNavView
            },
            component: loadView("view-vehicle-detail"),
            props: true
        },
        {
            path: "/dealer-groups",
            name: "groups",
            meta: {
                layout: sideNavView
            },
            component: loadView("dealer/view-groups")
        },
        {
            path: "/users",
            name: "users",
            meta: {
                layout: sideNavView
            },
            component: loadView("view-users")
        },
        {
            path: "/login",
            name: "login",
            meta: {
                requiresAuth: false,
                title: "Login",
                layout: sideView
            },
            component: loadView("account/view-login")
        },
        {
            path: "/reset-password",
            name: "reset-password",
            meta: {
                requiresAuth: false,
                title: "Reset Password",
                layout: sideView
            },
            component: loadView("account/view-reset")
        },
        {
            path: "/reset-password-mfa",
            name: "reset-password-mfa",
            meta: {
                requiresAuth: false,
                title: "Reset Password",
                layout: sideView
            },
            component: loadView("account/view-reset-mfa"),
            props: true
        },
        {
            path: "/register",
            name: "register",
            meta: {
                requiresAuth: false,
                title: "Register",
                layout: sideView
            },
            component: loadView("account/view-register")
        },
        {
            path: "/register/complete",
            name: "register-complete",
            meta: {
                requiresAuth: false,
                title: "Register",
                layout: sideView
            },
            component: loadView("account/view-register-complete"),
            props: route => ({ ...route.query })
        },
        {
            path: "/",
            redirect: "/home"
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: "/home"
        }
    ],
    history: createWebHashHistory()
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth == null || record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            return next({
                name: "login",
                query: { redirect: to.fullPath }
            });
        }
    }
    if (to.matched.some(record => record.path === "/home")) {
        if (auth.user.isDealershipUser || auth.user.isSystemsAdministrator) {
            return next({
                name: "dealer-home"
            });
        }
        if (!auth.user.streetAddressLine1) {
            return next({
                name: "user-profile-complete"
            });
        }
        if (!auth.user.walletValue) {
            return next({
                name: "user-profile-funds"
            });
        }
        return next({
            name: "user-home"
        });
    }
    next();
});
router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});
export default router;
