import { defineComponent, onMounted, onUnmounted, ref, computed, watch, nextTick } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { openModal } from "jenesius-vue-modal";
import DxTabs from "devextreme-vue/tabs";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { formatNumber } from "devextreme/localization";
import ModalWalletView from "../views/user/view-modal-wallet.vue";
import auth from "../services/auth";
import bidService from "../services/bids";
import { watchlistService } from "../services/watchlist";
export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const tabsRef = ref();
        const selectedTab = ref();
        const user = computed(() => auth.user?.firstName.substr(0, 1) || "" + auth.user?.lastName.substr(0, 1) || "");
        const watchlistIcon = ref("bi-star");
        const wallet = ref(0);
        const tabs = [
            {
                text: "Home",
                path: "/user-home"
            },
            {
                text: "Auctions",
                path: "/user-auctions"
            },
            {
                text: "Contact",
                path: "/contact"
            }
        ];
        function UpdateWallet() {
            bidService.getWallet().then((value) => {
                wallet.value = value || 0;
            });
        }
        async function UpdateWatchlist() {
            watchlistIcon.value = await watchlistService.hasWatchlist() ? "bi-star-fill text-warning" : "bi-star";
        }
        function handleTabSelected(e) {
            if (e.itemData) {
                router.push(e.itemData.path);
            }
        }
        function handleWalletClick() {
            openModal(ModalWalletView);
        }
        function handleLogOutclick() {
            auth.logOut();
            router.push("/login");
        }
        function updateSelection() {
            nextTick(() => {
                if (tabsRef.value) {
                    const index = tabs.findIndex(_ => _.path == route.path || route.path.startsWith(_.path));
                    selectedTab.value = index;
                }
            });
        }
        onMounted(() => {
            updateSelection();
            UpdateWallet();
            UpdateWatchlist();
            window.addEventListener("Wallet", UpdateWallet);
            window.addEventListener("Watchlist", UpdateWatchlist);
        });
        onUnmounted(() => {
            window.removeEventListener("Wallet", UpdateWallet);
            window.removeEventListener("Watchlist", UpdateWatchlist);
        });
        watch(() => route.path, () => updateSelection());
        return {
            user,
            watchlistIcon,
            wallet,
            tabs,
            selectedTab,
            handleTabSelected,
            handleWalletClick,
            handleLogOutclick,
            formatNumber,
            tabsRef
        };
    },
    components: {
        DxTabs,
        DxToolbar,
        DxItem
    }
});
