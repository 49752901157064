import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "d-flex align-items-center justify-content-end" };
const _hoisted_2 = { class: "text-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DxLabel = _resolveComponent("DxLabel");
    const _component_DxItem = _resolveComponent("DxItem");
    const _component_DxGroupItem = _resolveComponent("DxGroupItem");
    const _component_DxRequiredRule = _resolveComponent("DxRequiredRule");
    const _component_DxValidationSummary = _resolveComponent("DxValidationSummary");
    const _component_DxButtonItem = _resolveComponent("DxButtonItem");
    const _component_BtnNav = _resolveComponent("BtnNav");
    const _component_b_button_group = _resolveComponent("b-button-group");
    const _component_DxForm = _resolveComponent("DxForm");
    return (_openBlock(), _createElementBlock("form", {
        onSubmit: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.handlePaymentClick && _ctx.handlePaymentClick(...args)))
    }, [
        _createVNode(_component_DxForm, {
            class: "mx-5",
            "form-data": _ctx.data
        }, {
            default: _withCtx(() => [
                _createVNode(_component_DxGroupItem, { caption: "Add Funds to your Account" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_DxItem, {
                            "data-field": "type",
                            "editor-type": "dxRadioGroup",
                            "editor-options": { items: _ctx.paymentTypes, layout: 'horizontal' }
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxLabel, { visible: false })
                            ]),
                            _: 1
                        }, 8, ["editor-options"]),
                        _createVNode(_component_DxGroupItem, { "col-count": 2 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxItem, {
                                    "data-field": "totalVehicles",
                                    "editor-type": "dxNumberBox",
                                    "editor-options": { min: 1, max: 5, showSpinButtons: true }
                                }),
                                _createVNode(_component_DxItem, {
                                    "data-field": "chargeAmount",
                                    "editor-type": "dxNumberBox",
                                    "editor-options": { value: _ctx.data.totalVehicles * 100, readOnly: true, format: { type: 'currency' } }
                                }, null, 8, ["editor-options"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                _createVNode(_component_DxGroupItem, { caption: "Credit Card Information" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_DxGroupItem, {
                            visible: _ctx.data.type === 'Credit Card'
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxGroupItem, { "col-count": 2 }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxItem, {
                                            "data-field": "paymentType.expirationDate",
                                            "editor-type": "dxDateBox",
                                            "editor-options": { placeholder: 'mm/yy', displayFormat: 'MM/yy', dateSerializationFormat: 'yyyy-MM', useMaskBehavior: true, showDropDownButton: false }
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_DxLabel, { text: "ExpirationDate" }),
                                                _createVNode(_component_DxRequiredRule)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_DxItem, {
                                            "data-field": "paymentType.cardCode",
                                            "editor-options": { mask: '000' }
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_DxLabel, { text: "CVV" }),
                                                _createVNode(_component_DxRequiredRule)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_DxItem, {
                                    "data-field": "paymentType.cardNumber",
                                    "editor-options": { mask: '0000 0000 0000 0000' }
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxLabel, { text: "Card Number" }),
                                        _createVNode(_component_DxRequiredRule)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }, 8, ["visible"]),
                        _createVNode(_component_DxGroupItem, {
                            visible: _ctx.data.type === 'Bank Transfer'
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxGroupItem, { "col-count": 2 }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxItem, { "data-field": "paymentType.bankName" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_DxLabel, { text: "Bank Name" }),
                                                _createVNode(_component_DxRequiredRule)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_DxItem, { "data-field": "paymentType.routingNumber" }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_DxLabel, { text: "Routing Number" }),
                                                _createVNode(_component_DxRequiredRule)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_DxItem, { "data-field": "paymentType.nameOnAccount" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxLabel, { text: "Account Member Name" }),
                                        _createVNode(_component_DxRequiredRule)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_DxItem, { "data-field": "paymentType.accountNumber" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxLabel, { text: "Account Number" }),
                                        _createVNode(_component_DxRequiredRule)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }, 8, ["visible"]),
                        _createVNode(_component_DxItem, { "data-field": "firstNameBilling" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxLabel, { text: "First Name" }),
                                _createVNode(_component_DxRequiredRule)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_DxItem, { "data-field": "lastNameBilling" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxLabel, { text: "Last Name" }),
                                _createVNode(_component_DxRequiredRule)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_DxItem, { "data-field": "addressBilling" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxLabel, { text: "Address" }),
                                _createVNode(_component_DxRequiredRule)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_DxItem, { "data-field": "zipBilling" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxLabel, { text: "Zip" }),
                                _createVNode(_component_DxRequiredRule)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_DxGroupItem, { "col-count": 2 }, {
                            default: _withCtx(() => [
                                _createVNode(_component_DxItem, {
                                    "data-field": "terms",
                                    "editor-type": "dxCheckBox",
                                    "editor-options": { text: 'I agree to the terms & conditions' }
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_DxLabel, { visible: false }),
                                        _createVNode(_component_DxRequiredRule)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_DxItem, null, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_1, [
                                            _createElementVNode("h3", null, "Total Amount: " + _toDisplayString(_ctx.formatNumber(_ctx.data.chargeAmount, "currency")), 1)
                                        ])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }),
                _createVNode(_component_DxItem, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_DxValidationSummary, { items: _ctx.errors }, null, 8, ["items"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_DxGroupItem, { "col-count": 2 }, {
                    default: _withCtx(() => [
                        _createVNode(_component_DxButtonItem, {
                            "button-options": { text: 'Load Your Wallet', icon: _ctx.busy ? ' spinner-border' : 'check', type: 'default', disabled: _ctx.busy, useSubmitBehavior: true, width: '100%' }
                        }, null, 8, ["button-options"]),
                        _createVNode(_component_DxItem, { visible: _ctx.showNav }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_b_button_group, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_BtnNav, {
                                                text: "Back",
                                                icon: "bi bi-chevron-left",
                                                variant: "light",
                                                name: "user-profile-complete"
                                            }),
                                            _createVNode(_component_BtnNav, {
                                                text: "Skip this Step",
                                                variant: "light",
                                                name: "user-profile-preferences"
                                            })
                                        ]),
                                        _: 1
                                    })
                                ])
                            ]),
                            _: 1
                        }, 8, ["visible"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["form-data"])
    ], 32));
}
