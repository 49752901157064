export default [
    {
        text: "Dashboard",
        path: "/dealer-home",
        icon: "/icons/dashboard.svg"
    },
    {
        text: "Auctions",
        path: "/dealer-auctions",
        icon: "/icons/auction.svg"
    },
    {
        text: "Dealers",
        path: "/clients",
        icon: "/icons/clients.svg"
    },
    {
        text: "Vehicles",
        path: "/vehicles",
        icon: "/icons/car.svg"
    },
    {
        text: "Groups",
        path: "/dealer-groups",
        icon: "user"
    },
    {
        text: "Users",
        path: "/users",
        icon: "user"
    }
];
