import { defineComponent } from "vue";
import { closeModal } from "jenesius-vue-modal";
import WalletFundsView from "./view-wallet-funds.vue";
export default defineComponent({
    setup() {
        return {
            closeModal
        };
    },
    components: {
        WalletFundsView
    }
});
