import { defineComponent, ref, computed, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../services/auth";
export default defineComponent({
    props: {
        toggleMenuFunc: Function
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const title = ref(document.title);
        const user = computed(() => auth.user?.firstName.substr(0, 1) || "" + auth.user?.lastName.substr(0, 1) || "");
        function handleLogOutclick() {
            auth.logOut();
            router.push("/login");
        }
        watch(() => route.path, () => title.value = document.title);
        return {
            title,
            user,
            handleLogOutclick
        };
    },
    components: {
        DxToolbar,
        DxItem
    }
});
