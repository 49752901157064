import { createStore } from "devextreme-aspnet-data-nojquery";
import DataSource from "devextreme/data/data_source";
import urlJoin from 'url-join';
import auth from './auth';
import OtpClient from "./otpclient";
const apiUrl = process.env.VUE_APP_API;
class WatchlistService {
    async addWatchlist(body) {
        const response = await new OtpClient().post("Auction/AddVehicleWatchlist", body);
        ds.store().push([{ type: "insert", data: { ...body, ...{ vehicle: response.envelopeBody.vehicle } }, key: body.vehicleId }]);
    }
    async removeWatchlist(body) {
        await new OtpClient().post("Auction/RemoveVehicleWatchlist", body);
        ds.store().push([{ type: "remove", key: body.vehicleId }]);
    }
    async hasWatchlist() {
        const response = await new OtpClient().post("Auction/WatchlistPopulated");
        return response.envelopeBody;
    }
}
const watchlistService = new WatchlistService();
const ds = new DataSource({
    paginate: false,
    store: createStore({
        key: "vehicleId",
        loadUrl: urlJoin(apiUrl, "Auction/GetUserWatchlist"),
        onBeforeSend(operation, ajaxSettings) {
            if (!ajaxSettings.headers) {
                ajaxSettings.headers = {};
            }
            ajaxSettings.headers.Authorization = "Bearer " + auth.token;
        }
    }),
    map(dataItem) {
        dataItem.vehicle.background = "bg-success";
        dataItem.vehicle.border = "border-success";
        dataItem.vehicle.color = "text-success";
        dataItem.vehicle.variant = "success";
        return dataItem;
    }
});
export { watchlistService, ds };
