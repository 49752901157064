import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "vh-100" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DxList = _resolveComponent("DxList");
    return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(_component_DxList, {
            ref: "listRef",
            height: "auto",
            class: "border-bottom",
            items: _ctx.navigation,
            "key-expr": "path",
            "selection-mode": "single",
            "active-state-enabled": false,
            "focus-state-enabled": false,
            onItemClick: _ctx.handleItemClick
        }, null, 8, ["items", "onItemClick"])
    ]));
}
